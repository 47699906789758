import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/ghilas_avatar.png";

import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillMail
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { useTranslation } from "react-i18next";
function Home2() {

  const { t } = useTranslation();
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            {t('home2.part1')}  <span className="purple"> {t('home2.part2')}  </span> {t('home2.part3')}
            </h1>

            <p className="home-about-body">
                  <p></p>
                  {t('home2.part4')} 
              <br />
              <br />{t('home2.part5')}
              <i>
                <b className="purple"> {t('home2.part6')} </b>
              </i>
              <br />
              <br />
              {t('home2.part7')} &nbsp;
              <i>
                <b className="purple"> {t('home2.part8')} </b>  {t('home2.part8a')} {" "}
                <b className="purple">
                {t('home2.part9')}
                </b>  {t('home2.part10')}  <b className="purple">
                {t('home2.part11')} 
                </b>  {t('home2.part12')}  <b className="purple">
                {t('home2.part13')}
                </b>
              </i>
              <br />
              <br />
              {t('home2.part14')} <b className="purple"> {t('home2.part15')} </b> {t('home2.part16')}
              <i>
                <b className="purple">
                  {" "}
                  {t('home2.part17')}
                </b>  {t('home2.part18')}  <b className="purple">
                  {" "}
                  {t('home2.part19')}
                </b>
              </i>
              <br />
              <br />
              <i>  {t('home2.part20')} <b className="purple"> {t('home2.part21')} </b> {t('home2.part22')} <b className="purple">{t('home2.part23')}</b> </i>
              <br />
              <br />
        <i>  {t('home2.part24')}  </i> 
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social" >
            <h1> {t('home2.part25')}</h1>
            <p>
            {t('home2.part26')} <span className="purple"> {t('home2.part27')}</span>
            </p>
            <ul className="home-about-social-links" class="d-flex justify-content-center ">
              <li className="" style={{marginRight:20, listStyleType: 'none'}}>
                <a
                  href="https://github.com/ghilaslinz?tab=repositories"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              
              <li className="" style={{marginRight:20, listStyleType: 'none'}}>
                <a
                  href="https://www.linkedin.com/in/ghilas-belkaci-b2a292156/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>


              <li className="" style={{marginRight:20, listStyleType: 'none'}}>
                <a
                  href="mailto:ghilas.belkaci@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillMail />
                </a>
              </li>
              
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
