import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";


import javascriptBasic from './images/javascriptBasic.PNG'
import sql from './images/sql.PNG'
import javascriptopen from './images/javascriptopen.PNG'
import Reactjs from './images/Reactjs.PNG'
import accessible from './images/accessible.PNG'
import web from './images/web.PNG'
import ux from './images/ux.PNG'
import uxx from './images/uxx.PNG'
import uxxx from './images/uxxx.jpg'
import { useTranslation } from "react-i18next";
function Attestation() {

  const { t } = useTranslation();
  const [open, setDialogOpen] = React.useState(false);
  const [openWeather, setDialogOpenWeather] = React.useState(false);
  const [openCommerce, setDialogOpenCommerce] = React.useState(false);
  const [openChat, setDialogOpenChat] = React.useState(false);
  const [openFacebook, setDialogOpenFacebook] = React.useState(false);
  const [openGraphics, setDialogOpenGraphics] = React.useState(false);
  const [openMeteo, setDialogOpenMeteo] = React.useState(false);
  const [opencommwordpress, setDialogOpencommwordpress] = React.useState(false);
  const [opensolidarite, setDialogOpensolidarite] = React.useState(false);


  const certificateData = [
    {
      img: javascriptBasic,
      title: "Javascript Certification",
      link: 'https://www.hackerrank.com/certificates/34aae1ec1523',
    },
    {
      img: sql,
      title: 'SQL Certification',
      link: 'https://www.hackerrank.com/certificates/1d8ffda4302e',
    },

    {
      img: javascriptopen,
      title: t("attestation.part1"),
      link: ' https://openclassrooms.com/en/course-certificates/5109839237',
    },
    {
      img: Reactjs,
      title: t("attestation.part2"),
      link: 'https://openclassrooms.com/en/course-certificates/5109839237',
    },
    {
      img: accessible,
      title: t("attestation.part3"),
      link: ' https://openclassrooms.com/en/course-certificates/5109839237',
    },
    {
      img: web,
      title: t("attestation.part4"),
      link: 'https://openclassrooms.com/en/course-certificates/6565398439',
    },
  
    {
      img: uxxx,
      title: t("attestation.part5"),
      link: ' http://ude.my/UC-59d36919-3b6a-4096-8726-b2072f52c757',
    },
    {
      img: ux,
      title: t("attestation.part6"),
      link: ' https://openclassrooms.com/en/course-certificates/5109839237',
    },
    {
      img: uxx,
      title: t("attestation.part7"),
      link: 'https://openclassrooms.com/en/course-certificates/8722384679',
    },
    
  ];
  



  return (
    <Container fluid className="project-section" id="ceritificates">
      <Particle />
      <Container>
        <h1 className="project-heading">
        {t('attestation.part8')} <strong className="purple">{t('attestation.part9')}</strong>
        </h1>
        <p style={{ color: "white" }}>
        {t('attestation.part10')}
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <p>hello</p>
        {certificateData.map((certificate) =>{
           return(
            <Col md={4} className="project-card"  >
            <a href={certificate.link} target="_blank">
              <ProjectCard
              key={certificate.title}
                imgPath={certificate.img}
                isBlog={false}
                title={certificate.title}
             
              />
              </a>
            </Col>
           )   
        })}
       






        </Row>
      
      </Container>
    </Container>
  );
}

export default Attestation;
