import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
export default function ResponsiveDialog({open, handleClose, title, description,img, technologies, link}) {
    
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));




  return (
    <div>
        {/*
      <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button>
        */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
 <a href={link}  target='_blank'> 
<CardMedia
        component="img"
        height="250"
        image={img}
        alt=""
        

      />

</a>
        <DialogTitle id="responsive-dialog-title">
        <a href={link}  target='_blank'>    {title}  <CallMadeIcon/>    </a>
        </DialogTitle>
        <DialogContent 
      >
        
        
          
        
     
          {description }
      

        
          <DialogContentText  sx={{   marginTop:"20px" }}>
          < Divider  />
          { technologies}
          

          </DialogContentText>
          
        
       
        </DialogContent>

   

        <DialogActions>
        
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
