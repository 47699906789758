import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/ghilas_avatar.png";
import { useTranslation } from "react-i18next";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillMail
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Contact() {

  const { t } = useTranslation();
  return (
    <Container fluid className="home-about-section" id="contact" >
      <Container>
      
        <Row>
          <Col md={12} className="home-about-social" >
            <h1>{t('home2.part25')} </h1>
            <p>
            {t('home2.part26')}  <span className="purple">{t('home2.part27')} </span>
            </p>
            <ul className="home-about-social-links" class="d-flex justify-content-center ">
              <li className="" style={{marginRight:20, listStyleType: 'none'}}>
                <a
                  href="https://github.com/ghilaslinz?tab=repositories"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              
              <li className="" style={{marginRight:20, listStyleType: 'none'}}>
                <a
                  href="https://www.linkedin.com/in/ghilas-belkaci-b2a292156/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>


              <li className="" style={{marginRight:20, listStyleType: 'none'}}>
                <a
                  href="mailto:ghilas.belkaci@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillMail />
                </a>
              </li>
              
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Contact;
