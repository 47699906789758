import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import Dropdown from 'react-bootstrap/Dropdown';
import { ImBlog } from "react-icons/im";
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";

import { CgFileDocument } from "react-icons/cg";
import { useTranslation, Trans } from 'react-i18next';

const lngs = {
  en: { nativeName: 'En' },
  fr: { nativeName: 'Fr' }
};

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const { t, i18n } = useTranslation();
  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)} style={{  color:"white" }} >
                <AiOutlineHome style={{ marginBottom: "2px" }} />
              {/* <AnchorLink href='#home'  style={{  color:"white" }}>Home</AnchorLink>*/}
              Home
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                href='#about'  style={{  color:"white" }}
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                About 
                  
              </Nav.Link>
              </Nav.Item>


              <Nav.Item>

       
<Nav.Link
 style={{  color:"white" }} href='#project'
  onClick={() => updateExpanded(false)}
>
  <AiOutlineFundProjectionScreen
    style={{ marginBottom: "2px" }}
  />{" "}
My projects
</Nav.Link>
</Nav.Item>

              <Nav.Item>
              <Nav.Link
                style={{  color:"white" }} href='#techstack'
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
               My Skills
              </Nav.Link>
              </Nav.Item>


            

              <Nav.Item>
              <Nav.Link
                style={{  color:"white" }} href='#ceritificates'
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
               My Certificates
              </Nav.Link>
              </Nav.Item>


              <Nav.Item>
              <Nav.Link
               style={{  color:"white" }} href='#contact'
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
              Hire Me
                
              </Nav.Link>
              
              </Nav.Item>


           <Nav.Item className="fork-btn">
           <Dropdown>
  <Dropdown.Toggle variant="primary">
    {lngs[i18n.resolvedLanguage].nativeName}
  </Dropdown.Toggle>

  <Dropdown.Menu>
    {Object.keys(lngs).map((lng) => (
      <Dropdown.Item
        key={lng}
        active={i18n.resolvedLanguage === lng}
        onClick={() => i18n.changeLanguage(lng)}
      >
        {lngs[lng].nativeName}
      </Dropdown.Item>
    ))}
  </Dropdown.Menu>
</Dropdown>

            </Nav.Item>


          </Nav>
        </Navbar.Collapse>
        <div>
        

        </div>
      </Container>
    </Navbar>
  );
}

export default NavBar;
