import React, {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import ResponsiveDialog from "./Dialog";
import publisher from './images/publisher.png'
import ecommerce from './images/ecommerce.PNG'
import webchat from './images/webchat.PNG'
import webchatchat from './images/webchatchat.PNG'
import facebook from './images/facebook.PNG'
import graphics from './images/graphics.PNG'
import meteo from './images/meteo.PNG'
import wordpress from './images/wordpress.PNG'
import zombie from './images/zombieUx.PNG'
import ecommerceux from './images/ecommerceux.PNG'
import isolidarite from './images/isolidarite.PNG'
import api from './images/api.PNG'
import devjobs from './images/devjobs.png'
import Techstack from "./Techstack";
import './style.css'
import { NoEncryption } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
function Projects() {
  const { t } = useTranslation();
  const [isVisibleAll, setIsVisibleAll] = useState(true);
  const [isVisibleWordpress, setIsVisibleWordpress] = useState(false);
  const [isVisibleFront, setIsVisibleFront] = useState(false);
  const [isVisibleDesign, setIsVisibleDesign] = useState(false);

  const [activeButton, setActiveButton] = useState(1);
  const [openDialog, setOpenDialog] = useState(null);


  const projectData = [
    {
      id: 1,
      title: t("projects.titleId1"),
      imgPath: publisher,
      technologies: "Reactjs, CSS, Bootstrap, Firebase, Material_UI",
      description: t("projects.descId1"),
      link: "https://auth-thesis-developement.web.app/",
      categories: ["All Projects", "Front End"],
    },
    {
      id: 2,
      title: t("projects.titleId2"),
      imgPath: api,
      technologies: "React js, CSS, API, Material Ui",
      description: t("projects.descId2"),
      link: "https://receipeapp-31419.web.app/",
      categories: ["All Projects", "Front End"],
    },
    {
      id: 3,
      title: t("projects.titleId3"),
      imgPath: ecommerce,
      technologies: "Reactjs, CSS, Firebase, Material_UI",
      description:t("projects.descId3"),
      link: "https://challenge-db28b.web.app/",
      categories: ["All Projects", "Front End"],
    },
    {
        id: 4,
        title: t("projects.titleId4"),
        imgPath: webchat,
        technologies: "Reactjs, CSS, Firebase, Material_UI",
        description: t("projects.descId4"),
        link: "https://chatappreact-8a8f6.web.app/",
        categories: ["All Projects", "Front End"],
        isVisible: true,
      },
      {
        id: 5,
        title: t("projects.titleId5"),
        imgPath: facebook,
        technologies: "Reactjs, CSS, Firebase, Material_UI",
        description:t("projects.descId5"),
        link: "https://editor-69f02.web.app/",
        categories: ["All Projects", "Front End"],
        isVisible: true,
      },
      {
        id: 6,
        title: t("projects.titleId6"),
        imgPath: meteo,
        technologies: "Reactjs, CSS, Firebase, Material_UI",
        description:t("projects.descId6"),
        link: "https://ghilaslinz.github.io/",
        categories: ["All Projects", "Front End"],
        isVisible: true,
      },
      {
        id: 7,
        title: t("projects.titleId7"),
        imgPath: graphics,
        technologies: "Reactjs, CSS, Firebase, Material_UI",
        description:t("projects.descId7"),
        link: "https://computer-graphics-project-5s3zpy0aa-ghilaslinz.vercel.app/",
        categories: ["All Projects", "Front End"],
        isVisible: true,
      },
      {
        id: 8,
        title: t("projects.titleId8"),
        imgPath: zombie,
        technologies: "Figma",
        description:
          "",
        link: "https://www.behance.net/gallery/133125713/UXUI-study-for-a-mobile-app?tracking_source=for_you_feed_user_published",
        categories: ["All Projects", "Design"],
        isVisible: true,
      },
      {
        id: 9,
        title: t("projects.titleId9"),
        imgPath: ecommerceux,
        technologies: "Figma",
        description:
          "",
        link: "https://www.behance.net/gallery/132997487/UXUI-case-study-responsive-e-commerce-website?tracking_source=for_you_feed_user_published",
        categories: ["All Projects", "Design"],
        isVisible: true,
      },
      {
        id: 10,
        title: t("projects.titleId10"),
        imgPath: devjobs,
        technologies: "React Js, Bootstrap, Firebase",
        description:t("projects.descId10"),
        link: "https://devjobs-3c09b.web.app/",
        categories: ["All Projects", "Front End"],
        isVisible: true,
      },
    
    
  ];
  
  const handleButtonClick = (id) => {
    setActiveButton(id);
    switch (id) {
      case 1:
        setIsVisibleWordpress(true);
        setIsVisibleAll(true);
        setIsVisibleFront(true);
        setIsVisibleDesign(true);
        break;
      case 2:
        setIsVisibleWordpress(false);
        setIsVisibleAll(false);
        setIsVisibleFront(true);
        setIsVisibleDesign(false);
        break;
      case 3:
        setIsVisibleWordpress(true);
        setIsVisibleAll(false);
        setIsVisibleFront(false);
        setIsVisibleDesign(false);
        break;
      case 4:
        setIsVisibleWordpress(false);
        setIsVisibleAll(false);
        setIsVisibleFront(false);
        setIsVisibleDesign(true);
        break;
      default:
        break;
    }
  };

  return (
    <Container fluid className="project-section" id="project">
      <Particle />
   
      <Container>
    
        <h1 className="project-heading">
        {t('projects.part1')}   <strong className="purple">{t('projects.part2')}  </strong>
        </h1>
        <p style={{ color: "white" }}>
        {t('projects.part3')} 
        </p>

        <ul className="container_button">
  {["All Projects", "Front End", "Design"].map((label, index) => (
    <li
      key={index}
      className={`project-filter-btn ${activeButton === index + 1 ? "white" : ""}`}
      onClick={() => handleButtonClick(index + 1)}
    >
      {label}
    </li>
  ))}
</ul >
<Row>
        {projectData.map((project) => {
  if (
    (activeButton === 1) ||
    (activeButton === 2 && project.categories.includes("Front End")) ||
  //  (activeButton === 3 && project.categories.includes("Wordpress")) ||
    (activeButton === 3 && project.categories.includes("Design"))
  ) {
    
    return (
      < >
        <Col
          md={4}
          className="project-card"
          style={{ display: 'block' }}
          onClick={() => setOpenDialog(project.id)}
        >
          <ProjectCard imgPath={project.imgPath} isBlog={false} title={project.title} />
        </Col>
        <ResponsiveDialog
          open={openDialog === project.id}
          handleClose={() => setOpenDialog(null)}
          title={project.title}
          description={project.description}
          img={project.imgPath}
          link={project.link}
          technologies={project.technologies}
        />
      </>
      
    );
  } else {
    return null;
  }
})}

</Row>
      </Container>
     
    </Container>
  );
}

export default Projects;
