import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            
               
                home : {
                    part1 : 'Hi There!',
                    part2 : 'I\'M'
            
                  
                },
                home2 : {
                    part1 : 'Let me',
                    part2 : 'Inroduce',
                    part3 : 'Myself',
                    
                    part4 : 'Holding a Master\'s degree in Computer Science, with a specialization in Software Engineering, from Johannes Kepler University in Austria, web development is at the heart of my interests. This passion has allowed me to develop a wide range of valuable skills and gain a deep understanding of various technologies',
                    part5 : 'My expertise in web development is evidenced by my proficiency in',
                    part6 : 'React js, Javascript, HTML and CSS',
                    part7 : 'I am driven by the design and development of innovative',
                    part8 : 'Web Technologies and Products',
                    part8a: '',
                    part9: ' I consistently strive to provide an optimal user experience,',
                    part10 : 'meticulously adhering to',
                    part11: 'UX/UI design principles, accessibility, ',
                    part12 : 'and',
                    part13:'usability.',
                    part14:'In addition, I utilize my passion for development by working with ',
                    part15:'Wordpress, ',
                    part16:'combining modern JavaScript libraries like ',
                    part17:'React.js',
                    part18:' and widely used frameworks such as',
                    part19:'Bootstrap.',
                    part20:'I also have experience with prototyping tools like ',
                    part21 : 'Figma, Adobe Illustrator',
                    part22 : 'and',
                    part23 : 'Adobe Photoshop.',
                    part24 : 'I have the ability to communicate in five languages: English, French, German, Arabic, and Kabyle. These language skills enhance my capacity to work effectively with a variety of stakeholders.',

                    part25 : 'FIND ME ON',
                    part26 : 'Feel free to',
                    part27 : 'contact me',
                  
                },
                projects : {
                  part1 : 'My Recent',
                  part2 : 'Works',
                  part3 : 'Here are a few projects I\'ve worked on recently. (You can find the source code on my Github)',
                  
                  titleId1 : "Accessible Publisher with React js",
                  titleId2: "Receipe App that fetch data from an API with React js",
                  titleId3: "Fullstack E-Commerce website with React js",
                  titleId4: "Chat app with React js and Google Authentification",
                  titleId5: "Facebook clone with React js and Firebase",
                  titleId6: "Weather App with javascript",
                  titleId7:  "Computer graphics project with Javascript and WEBGL",
                  titleId8:  "UX/UI case study : Zombie mobile App",
                  titleId9:  "UX/UI case study : e-commerce website",
                  titleId10: " Job search web application with React Js",
                  descId1 : "Accessible publisher that allows the user to create professionnal paper that respects ICHHP requirements",
                  descId2 : "A online platform that allows to fetch receipes from an API",
                  descId3 : "Fullstack E-commerce website with login/signup features and Payement with Stripe",
                  descId4 :  "Chat app that are created with React js, it uses Google Authentification to login in the app",
                  descId5 : "Facebook clone with Google Authentification created with React js for the Front-End and Firebase for the Backend",
                  descId6 : "Weather App that fetch data from an API with Javascript",
                  descId7 :  "Project that uses Javascript and the library WEBGL",
                  descId8 : "",
                  descId9 : "",
                  descId10 : "ReactJS application facilitating job searches using city names or job titles.",
                  

                  
                 
                
              },
              about: {
                part1 : 'Professional',
                part2 : 'Skillset'
                
              
            },
            attestation: {
              part1 : 'Write Javascript for the Web : Certificate of achievement',
              part2 : 'Build a web application with React.js : Certificate of achievement',
              part3 : 'Make Your Web Content Accessible : Certificate of achievement',
              part4 : 'Code an Accessible Website in HTML/CSS : Certificate of achievement',
              part5 : 'Complete Web & Mobile Designer in 2021: UI/UX, Figma, +more',
              part6 : 'UX design: discover the fundamentals! : Certificate of achievement',
              part7 : 'Appropriate the UX approach in practice',
              part8 : "My Recent",
              part9 : "Ceritificates",
              part10 : "Here are a some Certifications and Formations that i made recently."
          },
          type: {
            part1 : 'Front-End Developer',
            part2 : 'Wordpress Developer'
            
        },
            
        }
      },
      fr: {
        translation: {
            
               
            home : {
                part1 : 'Salut!',
                part2 : 'Je suis'
                
              
            },
            home2 : {
                part1 : 'Permettez-moi',
                part2 : 'de me',
                part3 : 'présenter',
                part4 : 'Doté d\'un Master en Informatique avec une spécialisation en génie logiciel, obtenu à l\'Université Johannes Kepler en Autriche. Le développement web est au cœur de mes intérêts. Cette passion m\'a permis de développer une large palette de compétences précieuses ainsi qu\'une solide connaissance de diverses technologies',
                    part5 : ' Mon expertise en développement web est démontrée par ma maîtrise de',
                    part6 : 'React js, Javascript, HTML et CSS',
                    part7 : 'Je suis motivé par la conception et le développement de technologies et de produits web innovants',
                    part8 : 'Je m\'efforce constamment de fournir une expérience utilisateur de premier ordre',
                    part8a : 'en respectant scrupuleusement les principes de conception ',
                    part9: 'UX/UI, d\'accessibilité et d\'utilisabilité.',
                    part10 : '',
                    part11 : '',
                    part12 : '',
                    part13 : '',
                    part14:'En complément, j\'exploite ma passion pour le développement en travaillant avec ',
                    part15:'Wordpress,',
                    part16:'en combinant des bibliothèques Javascript modernes comme',
                    part17:'React.js',
                    part18:'et des frameworks largement utilisés tels que',
                    part19:'Bootstrap.',
                    part20:'J\'ai également de l\'expérience avec les outils de prototypage tels que ',
                    part21 : 'Figma, Adobe Illustrator',
                    part22 : 'et',
                    part23 : 'Adobe Photoshop.',
                    part24 : 'J\'ai la capacité de communiquer en cinq langues : Anglais, Français, Allemand, Arabe et Kabyle. Ces compétences linguistiques augmentent ma capacité à travailler efficacement avec une variété d\'interlocuteurs.',
                    part25 : 'ME CONTACTER',
                    part26 : 'n\'hésitez pas à',
                    part27 : 'me contacter',
              
            },
            projects : {
              part1 : 'Mes Projets récents',
              part2 : '',
              part3 : 'Voici quelques projets sur lesquels j\'ai travaillé récemment. (Vous pouvez trouver le code source sur mon Github)',
              
              
              titleId1: "Éditeur accessible avec React js",
              titleId2: "Application de recettes qui récupère des données depuis une API avec React js",
              titleId3: "Site e-commerce Fullstack avec React js",
                titleId4: "Application de chat avec React js et authentification Google",
                titleId5: "Clone de Facebook avec React js et Firebase",
                titleId6: "Application météo avec Javascript",
                titleId7: "Projet de graphisme informatique avec Javascript et WEBGL",
                titleId8: "Étude de cas UX/UI : Application mobile Zombie",
                titleId9: "Étude de cas UX/UI : site e-commerce",
                titleId10: "Application web de recherche d'emploi avec React Js",
                descId1: "Éditeur accessible permettant à l'utilisateur de créer des documents professionnels respectant les exigences de l'ICCHP",
                descId2: "Plateforme en ligne permettant de récupérer des recettes depuis une API",
                descId3: "Site e-commerce Fullstack avec fonctionnalités d'inscription/connexion et paiement avec Stripe",
                descId4: "Application de chat créée avec React js, utilisant l'authentification Google pour se connecter à l'application",
                descId5: "Clone de Facebook avec authentification Google, créé avec React js pour le Front-End et Firebase pour le Backend",
                descId6: "Application météo qui récupère des données depuis une API avec Javascript",
                descId7: "Projet utilisant Javascript et la bibliothèque WEBGL",
                descId8: "",
                descId9: "",
                descId10 : "Application ReactJS facilitant la recherche d'emploi à partir de noms de villes ou d'intitulés de postes.",
            
          },
          
        about: {
          part1 : 'Compétences',
          part2 : 'professionnelles'
          
        
      },
      attestation: {
        part1: "Écrire du Javascript pour le Web : Certificat de réussite",
        part2: "Créer une application web avec React.js : Certificat de réussite",
        part3: "Rendre votre contenu Web accessible : Certificat de réussite",
        part4: "Coder un site Web accessible en HTML/CSS : Certificat de réussite",
        part5: "Designer Web & Mobile complet en 2021 : UI/UX, Figma, et plus",
        part6: "UX design : découvrez les fondamentaux ! : Certificat de réussite",
        part7: "Appropriez-vous l'approche UX en pratique",
        part8: "Mes dernières",
        part9: "Certifications",
        part10: "Voici quelques certifications et formations que j'ai récemment suivies."
        },

        type: {
          part1 : 'Développeur Front-End',
          part2 : 'Développeur Wordpress'
          
      },
            
        }
      }
    }
  });

export default i18n;