import React from "react";

import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiHtml5,
  DiCss3,
  DiBootstrap,
  DiWordpress,
  DiIllustrator,
  DiPhotoshop,
 
  
} from "react-icons/di";
import {FaGitSquare} from "react-icons/fa";
import './style.css'
import { FaFigma } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import { SiPytorch, SiTensorflow, SiFirebase } from "react-icons/si";
import Particle from "../Particle";
import { useTranslation } from "react-i18next";
function Techstack() {
  const { t } = useTranslation();
  return (
    
    <Container fluid className="project-section" id="techstack">
         <Particle />
         <Container>
       <h1 className="project-heading">
       {t('about.part1')} <strong className="purple">{t('about.part2')} </strong>
        </h1>
   
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      <Col xs={4} md={2} className="tech-icons">
        <DiReact color="white" />
        <h5 style={{ color: 'white' }}>React Js</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 color="white" />
        <h5 style={{ color: 'white' }}>Javascript</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 color="white" />
        <h5 style={{ color: 'white' }}>HTML</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiCss3 color="white" />
        <h5 style={{ color: 'white' }}>CSS</h5>
      </Col>


      <Col xs={4} md={2} className="tech-icons">
        <DiBootstrap color="white" />
        <h5 style={{ color: 'white' }}>Bootstrap</h5>
      </Col>


      <Col xs={4} md={2} className="tech-icons">
        <FaGitSquare color="white" />
        <h5 style={{ color: 'white' }}>Git</h5>
      </Col>

     

      <Col xs={4} md={2} className="tech-icons">
        < DiWordpress color="white" />
        <h5 style={{ color: 'white' }}>Wordpress</h5>
      </Col>
     

      <Col xs={4} md={2} className="tech-icons">
        < SiFirebase color="white" />
        <h5 style={{ color: 'white' }}>Firebase</h5>
      </Col>
     
      <Col xs={4} md={2} className="tech-icons">
        < DiIllustrator color="white" />
        <h5 style={{ color: 'white' }}>Adobe Illustrator</h5>
      </Col>
     
      <Col xs={4} md={2} className="tech-icons">
        < DiPhotoshop color="white" />
        <h5 style={{ color: 'white' }}>Adobe Photoshop</h5>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        < FaFigma color="white" />
        <h5 style={{ color: 'white' }}>Figma</h5>
      </Col>
     

      
      
    </Row>
    </Container>
    </Container>
  );
}

export default Techstack;
